.input-label {
	height: 4rem;
	width: 99%;
	font-size: 1.2rem;
	border-width: 0px;
	border: none;
	box-shadow: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 5px;
	margin-left: -2px;
	box-sizing: border-box;
	padding: 0 0.8rem;
	color: var(--color-darkgray);
}
::-webkit-input-placeholder {
	/* Edge */
	color: #a8a8a8;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #a8a8a8;
}

::placeholder {
	color: #a8a8a8;
}

.input-label:focus {
	outline: none !important;
	border: 1px solid var(--color-primary);
}
.label-text {
	font-size: 1.2rem;
	cursor: pointer;
}
.margin-0-auto {
	margin: 0 auto;
}
.form-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.form-section {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.rows {
	flex-direction: column;
	width: 100%;
}
.input-container {
	width: 50%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0.5rem;
}
.width-80 {
	width: 80vw;
	margin: 0 auto;
}

.header-devis {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
}
.header-title {
	margin: 0;
	font-size: 4.5rem;
	background: -webkit-linear-gradient(
		rgba(142, 198, 63, 1),
		rgba(58, 181, 73, 1)
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.header-subtitle {
	margin: 0;
	font-size: 2.5rem;
	text-align: center;
}
.description-devis {
	width: 60%;
	font-size: 1.3rem;
	text-align: center;
}
.two-rows {
	display: flex;
	width: 103.8%;
	justify-content: space-between;
	margin-left: -7px;
}
.card-form-container {
	display: flex;
	justify-content: space-between;
}
.card-form {
	background-color: white;
	border-width: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18%;
	box-shadow: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 5px;
	font-size: 1.3rem;
	cursor: pointer;
	color: var(--color-darkgray);
	word-break: break-word;
}
.card-form:focus {
	outline: none !important;
	border: 1px solid var(--color-primary);
}
.all100p {
	min-width: 10%;
	border-width: 0px;
	background-color: white;
}
.medium {
	min-height: 3.5rem;
}
.height-9rem {
	height: 11rem;
	text-align: center;
	font-size: 1.1rem;
	box-sizing: border-box;
	padding: 0 0.5rem;
}
.width-17p {
	width: calc(100% / 5.5);
}
.big {
	min-height: 6rem;
}
.margin-top-1rem {
	margin-top: 1rem;
}
.margin-top-05rem {
	margin-top: 0.5rem;
}
.width-45p {
	width: 46%;
}
.width-32p {
	width: 32%;
}
.yes-no-icon {
	min-height: 50%;
	margin-right: 1rem;
}
.color-white {
	color: white;
}
.home-icon {
	height: 30%;
}
.modal {
	position: fixed;
	box-sizing: border-box;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	overflow-y: scroll;
	width: 70%;
	height: 60%;
	padding: 2rem 1.5rem;
	z-index: 1000;
}
.margin-bottom-1rem {
	margin-bottom: 0.8rem;
}
.justify-with-gap {
	justify-content: flex-start;
	gap: 2%;
}
.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(10px);
	z-index: 1000;
}
.close-Model {
	height: 100%;
	cursor: pointer;
}
.headerNavigation {
	display: flex;
	justify-content: space-around;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	height: 3rem;
	min-width: 10%;
}
.submit {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	align-items: center;
	width: 100%;
	margin: 2rem 2rem;
}
.submit-button {
	align-self: center;
	width: 50%;
	min-height: 5rem;
	color: white;
	border: none;
	background-color: var(--color-primary);
	font-size: 1.8rem;
	border-radius: 0.5rem;
	font-weight: bold;
	cursor: pointer;
}
.p-submit {
	align-self: center;
	text-align: center;
}
.small {
	font-size: 1rem;
	text-align: center;
	box-sizing: border-box;
	padding: 0.5rem;
	height: 3.5rem;
}
.color-red {
	color: var(--color-red);
}
.red-border {
	border: 2px solid var(--color-red);
}
.wrap {
	flex-wrap: wrap;
}
.color-blue-underline {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}
.checkBoxContainer {
	display: flex;
	align-items: center;
	gap: 0.5em;
}
.checkBox {
	display: flex;
	width: 1.5em;
	height: 1.5em;
	border-radius: 0.2em;
	border: 2px solid var(--color-primary);
	cursor: pointer;
	background-color: white;
}
p {
	word-wrap: break-word;
}
.bg-primary {
	background-color: var(--color-primary);
}
.check-icon {
	width: 80%;
}
.font-condition {
	font-size: 1rem;
}
.partenaire-title {
	color: var(--color-primary);
	font-weight: bolder;
	text-transform: uppercase;
	margin: 2rem 0rem;
}
.upload-container {
	margin: 0rem 10%;
	display: flex;
	flex-direction: column;
}
.upload-item {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100%;
	min-height: 5rem;
	background-color: white;
	border-radius: 1rem;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	box-shadow: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
	gap: 1rem;
	cursor: pointer;
}
.upload-item img {
	min-height: 1.5rem;
	width: 1.5rem;
}
.accepted-upload {
	background: var(--color-linear);
	color: white;
}
.upload-check {
	position: absolute;
	right: 12%;
	width: 1.5rem;
	min-height: 1.5rem;
}
.progressBar {
	position: fixed;
	height: 0.3rem;
	background: var(--color-linear);
	top: 0;
}
.disabled {
	opacity: 0.7;
	cursor: not-allowed;
}

/* tablette */
@media (max-width: 1000px) {
	.partenaire-title {
		margin: 1rem 0rem;
		font-size: 1.8rem;
	}
	.checkBoxContainer {
		max-width: 70%;
	}
	.checkBox {
		width: 2em;
	}
	.width-adresse {
		min-width: 102.5%;
	}
	.form-container {
		width: 100%;
	}
	.input-label {
		width: 100%;
	}
	.description-devis {
		width: 90%;
		font-size: 1rem;
	}
	.input-container-fully {
		width: 95%;
	}
	.two-rows {
		width: 80vw;
	}
	.height-9rem {
		height: 8rem;
		font-size: 1rem;
	}
	.headerNavigation {
		height: 2.5rem;
		min-width: 18%;
	}
	.modal {
		width: 80%;
	}
	.submit-button {
		width: 70%;
	}
}

/* smartphone */
@media (max-width: 600px) {
	.big {
		font-size: 1rem;
	}
	.medium {
		font-size: 1rem;
	}
	.input-label {
		font-size: 0.8rem;
	}
	.width-80 {
		width: 90vw;
	}
	.header-title {
		font-size: 2.5rem;
	}
	.header-subtitle {
		margin: 0;
		font-size: 1.5rem;
	}
	.label-text {
		font-size: 1rem;
	}
	.input-container-fully {
		width: 93.5%;
	}
	.two-rows {
		width: 90vw;
	}
	.height-9rem {
		height: 7rem;
		font-size: 0.6rem;
		padding: 0 2px;
	}
	.headerNavigation {
		height: 2rem;
		min-width: 28%;
	}
	.label-text {
		font-size: 0.8rem;
	}
	.modal {
		width: 90%;
	}
	.small {
		font-size: 0.8rem;
	}
	.submit-button {
		font-size: 1.3rem;
	}
	.width-17p {
		width: calc(100% / 3.5);
	}
	.checkBox {
		width: 3em;
	}
	.font-condition {
		font-size: 0.8rem;
	}
	.partenaire-title {
		font-size: 1.3rem;
		margin-top: 0;
	}
	.upload-item {
		font-size: 0.8rem;
		padding: 0rem 10%;
	}
}
