.top-right {
	border-radius: 5px;
	margin-top: 15px;
	width: fit-content;
	padding: 5px 10px;
	margin-left: auto;
	margin-right: 5vw;
	background-color: #fff;
}
.headerFont {
	color: var(--color-secondary);
	font-weight: 500;
}
.flex {
	display: flex;
}
.center {
	justify-content: center;
	align-items: center;
}
.column {
	flex-direction: column;
}
.color-primary {
	color: var(--color-primary);
	font-weight: bold;
}
.img-header {
	height: 20%;
}
.bg-linear {
	background: var(--color-linear);
}
.no-margin {
	margin: 0;
}
.width-card {
	width: 18.5%;
}
.big-font {
	font-size: 2.3rem;
	text-align: center;
}
.center-content {
	align-items: center;
	justify-content: center;
}
.margin-top-2 {
	margin-top: 2rem;
}
.img-brand {
	width: 60%;
}
.medium-font {
	margin: 0 1rem;
	font-weight: 600;
}
.gap-6 {
	gap: 6em;
}
.card-container {
	height: 300px;
	width: 300px;
	border-radius: 20px;
	cursor: pointer;
}
.bg-secondary {
	background-color: var(--color-secondary);
}
.padding-top {
	padding-top: 100px;
}
.bg-whiteblue {
	background-color: var(--color-whiteblue);
}
.card-text {
	color: #fff;
	font-size: 2rem;
	font-weight: 600;
	text-align: center;
}

.video {
	height: 50vh;
	background-color: gray;
	width: 70vw;
	box-shadow: var(--shadow);
}
.question {
	background-color: #fff;
	margin-top: 2em;
	box-shadow: var(--shadow);
	align-items: center;
	border-radius: 8px;
	width: 70vw;
	cursor: pointer;
	color: var(--color-darkgray);
}
.reponse {
	margin-top: auto;
	width: 80%;
	color: #000;
	font-weight: 400;
}
.items-space-between {
	padding: 1.5rem 0;
	width: 80%;
	justify-content: space-between;
	align-items: center;
}
.arrow {
	height: 100%;
}

.border-Bottom {
	height: 7px;
	background-color: var(--color-darkgray);
	border-radius: 5px;
	width: 10vw;
	margin-bottom: 1.5rem;
}
.slider-trois {
	display: flex;
	justify-content: center;
}
.img-slider {
	object-fit: cover;
	height: 10em;
	max-width: 95%;
	border-bottom: 2px #000;
	padding-top: 0.3rem;
}
.Custom-slider {
	background-color: #fff;
	height: 12rem;
	width: 70vw;
	box-sizing: border-box;
	box-shadow: var(--shadow);
}
.verified {
	height: 1.8rem;
	margin-right: 0.5rem;
}
.avis {
	display: flex;
	flex-direction: column;
	height: 94%;
	align-items: center;
	justify-content: space-around;
	width: 80%;
	margin: 0 auto;
}
.commentaire {
	text-align: center;
}
.commentaire::before {
	content: open-quote;
}
.commentaire::after {
	content: close-quote;
}
.avatar {
	width: 10vh;
	height: 10vh;
	border-radius: 5vh;
	object-fit: cover;
	margin-right: 0.5rem;
}
.margin-bot {
	margin-bottom: 50vh;
}
.Custom-slider-phone {
	width: 98vw;
	height: fit-content;
}
.avisPhone {
	width: 100vw;
}
.smart-img {
	border: 3px solid var(--color-primary);
}
.star {
	height: 1.5rem;
}
.blanco {
	margin: -10% 14vw 0 14vw;
	padding: 10% 0;
	background-color: white;
	width: 70vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	border-radius: 20px;
}
.actus-cards {
	width: 90vw;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}
.actus-card {
	width: 42vw;
	border-radius: 15px;
	box-shadow: var(--shadow);
	cursor: pointer;
}
.card-content {
	width: 90%;
	justify-content: space-between;
	margin: 0 auto;
	height: 25%;
}
.infos-card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 0;
	height: 100%;
}
.width-card-img {
	width: 100%;
	height: 70%;
	overflow: hidden;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	object-fit: cover;
}
.infos-title {
	color: var(--color-secondary);
	font-weight: 800;
	font-size: 1.1rem;
}
.center-self {
	margin: auto 0;
}
.big-image {
	height: 80%;
}
.small-content {
	height: 60%;
}
.hight-400 {
	height: 32em;
}
.hight-180 {
	height: 15em;
}
.space-around {
	justify-content: space-between;
}
.hashtag {
	color: var(--color-primary);
	font-weight: 800;
}
.hashtag::before {
	content: '#';
}
.right {
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1.2rem;
	width: 12em;
	margin-left: auto;
	margin-right: 4.5vw;
	color: var(--color-darkgray);
	border: 1px solid var(--color-darkgray);
	padding: 10px;
	font-weight: 500;
	font-size: 1rem;
	border-radius: 10px;
	cursor: pointer;
}
.spacing {
	height: 30px;
}
.color-white {
	color: #fff;
}
.fill-primary {
	cursor: pointer;
}
.text-center {
	text-align: center;
}
.height-30r {
	height: 23em;
	justify-content: space-around;
	box-shadow: var(--shadow);
	padding: 0 10vw;
}
.height-40 {
	height: 40vh;
	justify-content: space-around;
}
.input {
	display: flex;
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	width: 30vw;
	justify-content: space-between;
}
.inputMail {
	border-width: 0px;
	border: none;
	width: 80%;
}
.inputMail:focus {
	outline: none;
}
.mission-cards {
	width: 90vw;
	margin: 0 auto;
	gap: 1.5em;
	margin-bottom: 30px;
	box-sizing: border-box;
}
.Mission-card {
	box-sizing: border-box;
	padding: 0 1rem;
	width: 22%;
	height: 18rem;
	border-radius: 25px;
	background-color: white !important;
	justify-content: space-evenly;
	box-shadow: var(--shadow-light);
}
.num-mission {
	margin-top: 5%;
	margin-left: -110%;
	width: 1.5em;
	text-align: center;
	font-weight: 800;
	font-size: 2rem;
	border-radius: 10px;
}
.title-mission {
	font-weight: bold;
	color: var(--color-primary);
	font-size: 1.2rem;
	text-align: center;
}
.content-mission {
	margin-top: -0.2rem;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 12;
	-webkit-box-orient: vertical;
	white-space: normal;
	padding: 0 3%;
	font-size: 0.9rem;
	flex: 4;
	font-weight: bold;
	color: var(--color-secondary);
}
#center-content {
	justify-content: center;
	gap: 20%;
}
.color-secondary {
	color: var(--color-secondary);
}
.font-weight-500 {
	margin-top: 2%;
	font-weight: 500;
}
.Banner-buttons {
	width: 40em;
	display: flex;
	gap: 2.5em;
	height: 4em;
	margin-bottom: 2rem;
}
.banner-button {
	min-height: 60%;
	width: 45%;
	box-shadow: var(--shadow);
}
.bg-white {
	background-color: #fff !important;
}
.button-secondary {
	background: white;
	border: none;
	color: var(--color-secondary);
	padding: 10px 15px;
	text-align: center;
	border-radius: 12px;
	font-size: 1.25rem;
	cursor: pointer;
}
.margin-top-3rem {
	margin-top: 3rem;
}
.swiper-button-next {
	background-image: url(../assets/icons/swiperNext.svg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
}

.swiper-button-next::after {
	display: none;
}
.swiper-button-prev {
	background-image: url(../assets/icons/swiperPrev.svg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
}

.swiper-button-prev::after {
	display: none;
}
.margin-bottom-2 {
	margin-bottom: 2rem;
}
.margin-top-5 {
	margin-top: 5rem;
}
.new-top-right {
	position: absolute;
	top: 95px;
	right: 1rem;
}
.gap-small {
	gap: 1rem;
}
.small-full {
	width: 100%;
	justify-content: space-around;
}
.new-top-right h3 {
	text-align: center;
	margin-bottom: 0.5rem;
}
.new-buttons-header {
	display: flex;
	color: white;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
}
.new-buttons-header h5 {
	font-weight: 400;
}
.new-buttons-header h4 {
	font-weight: 500;
}
.new-buttons-header img {
	height: 1.5rem;
}
.btn-primary {
	background: var(--color-linear);
}
.btn-secondary {
	background-color: var(--color-secondary);
}
@media (max-width: 1000px) {
	.new-top-right {
		position: absolute;
		top: 105px;
		right: 1rem;
	}
	.margin-top-5 {
		margin-top: 6rem;
	}
	.big-font {
		font-size: 1.8rem;
	}
	.medium-font {
		font-size: 1.3rem;
		text-align: center;
	}
	.gap-6 {
		gap: 4rem;
	}
	.video {
		height: 40vh;
	}
	.reponse {
		width: 90%;
	}
	.question {
		width: 90vw;
	}
	.items-space-between {
		width: 95%;
	}
	.card-text {
		font-size: 1.4rem;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.Custom-slider {
		width: 90vw;
	}
	.img-slider {
		max-width: 95%;
	}
	.hight-400 {
		height: 30vh;
		width: 90vw;
	}
	.hight-180 {
		width: 90vw;
	}
	.actus-cards {
		flex-direction: column;
		gap: 1.5rem;
	}
	.big-image {
		height: 70%;
	}
	.small-content {
		height: 100%;
	}
	.right {
		margin: 0 auto;
	}
	.input {
		width: 60vw;
	}
	.height-40 {
		padding: 0 5vw;
	}
	.Mission-card {
		margin: 0 auto;
		width: 80vw;
		min-height: 10em;
		margin-bottom: 1rem;
	}
	.num-mission {
		margin-left: -105%;
		font-size: 1.8rem;
	}
	.button-secondary {
		font-size: 1rem;
		padding: 10px 10px;
	}
	.Banner-buttons {
		width: 100%;
		gap: 1.8em;
	}
	.Banner-button {
		width: 100%;
	}
	.height-30r {
		height: 18em;
	}
	.content-mission {
		margin-top: -1rem;
		font-size: 0.8rem;
		-webkit-line-clamp: 5;
	}
}

@media (max-width: 600px) {
	.top-right {
		margin-right: 0rem;
	}
	.Mission-card {
		height: 16em;
	}
	.big-font {
		font-size: 1.5rem;
	}
	.medium-font {
		font-size: 1rem;
		text-align: center;
	}
	.card-container {
		height: 40vw;
		width: 40vw;
		text-decoration: none;
	}
	.gap-6 {
		gap: 1rem;
	}
	.video {
		height: 20vh;
		background-color: gray;
		width: 98vw;
	}
	.img-slider {
		padding-top: 0;
	}
	.infos-title {
		color: var(--color-secondary);
		font-weight: 800;
		font-size: 0.8rem;
	}
	.hashtag {
		font-size: 0.6rem;
		color: var(--color-primary);
		font-weight: 800;
	}
	.center-self {
		font-size: 0.8rem;
	}
	.input {
		width: 70vw;
	}
	.height-40 {
		padding-bottom: 20vw;
	}
	.title-mission {
		font-size: 1.2rem;
	}
	.content-mission {
		margin-top: 0rem;
		font-size: 0.8rem;
		-webkit-line-clamp: 6;
	}
	.Banner-buttons {
		height: 3.5em;
	}
	.new-top-right {
		right: 0;
		width: 100%;
	}
	.new-buttons-header h4 {
		font-size: 0.75rem;
	}
}
