.img-meeting {
	height: 70vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
.solutions-text {
	margin: auto 15%;
	display: flex;
	flex-direction: column;
}
.solutions-text h1 {
	color: var(--color-secondary);
	align-self: center;
	text-align: center;
	font-family: 'Montserrat';
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 2rem;
}
.solutions-text p {
	margin-top: 2rem;
	text-align: center;
	color: var(--color-secondary);
	font-family: 'Montserrat';
	font-weight: 400;
}
.services-container {
	display: flex;
}
.service-cart {
	flex: 1;
	margin: 2rem 2%;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.service-cart hr {
	width: 20%;
	border: 0.1rem solid var(--color-primary);
	background-color: var(--color-primary);
}
.service-cart h1 {
	text-transform: uppercase;
	font-family: 'Montserrat';
	color: var(--color-secondary);
	font-size: 1.6rem;
	font-weight: bold;
	margin: 1rem 0rem;
}
.service-cart h2 {
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 1.5rem;
}
.service-cart p {
	font-weight: 400;
	font-size: 1rem;
	min-height: 2rem;
}
.service-cart img {
	width: 8%;
}
.service-cart button {
	margin-bottom: 1rem;
	border: 2px solid var(--color-primary);
	min-width: 50%;
	background-color: white;
	height: 4rem;
	border-radius: 10px;
	font-size: 1.2rem;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	transition: background 0.5s ease-in-out;
	/* a effacer */
	color: var(--color-primary);
}
.button-link {
	color: var(--color-primary);
	text-decoration: none;
}
.service-cart button:hover .button-link {
	color: white;
}
.service-cart button:hover {
	background-color: var(--color-primary);
	/* a effacer  */
	color: white;
}

.service-cart small {
	display: block;
}
.border-shadow {
	box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, 0.5);
	margin-bottom: 1rem;
}
.service-title {
	align-self: center;
	text-align: center;
}
.solutions-text hr {
	width: 20%;
	height: 0.4rem;
	border-radius: 0.5rem;
	border: none;
	background: var(--color-linear);
}
.modal-solutions p {
	margin-top: 2rem;
}
.title-solutions-modal {
	margin-top: 2rem;
	text-align: center;
}
.footer-solutions-container {
	display: flex;
	margin: 2rem 10% 0rem 10%;
	box-sizing: border-box;
}
.footer-info {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 5%;
	justify-content: space-between;
}
.footer-info h1 {
	text-transform: uppercase;
	color: var(--color-secondary);
}
.footer-info p {
	color: #606060;
	font-weight: 500;
	font-size: 1.2rem;
}
.img-femme {
	max-height: 60vh;
	align-self: flex-end;
	filter: drop-shadow(0 0 0.5rem);
	object-fit: contain;
}
.info-timing {
	display: flex;
	flex-direction: column;
}
.info-timing p {
	color: var(--color-secondary);
}
.phone-footer {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.phone-footer h1 {
	color: var(--color-primary);
}
.phone-footer img {
	height: 1.5rem;
}
.phone-footer {
	display: flex;
}

@media (max-width: 1000px) {
	.img-femme {
		max-height: 45vh;
		align-self: flex-end;
		filter: drop-shadow(0 0 0.5rem);
		object-fit: contain;
	}
	.img-meeting {
		height: 70vh;
	}
	.services-container {
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.solutions-text h1 {
		font-size: 1.5rem;
	}
	.img-meeting {
		height: fit-content;
	}
	.footer-info p {
		font-size: 0.9rem;
	}
	.footer-info h1 {
		font-size: 1.2rem;
	}
	.phone-footer img {
		height: 1rem;
	}
}
