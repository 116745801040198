@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap');
body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
button,
textarea {
	font-family: 'Montserrat', sans-serif;
}

:root {
	--color-primary: #3ab549;
	--color-secondary: #001051;
	--color-darkgray: #606060;
	--color-gray: #707070;
	--color-linear: linear-gradient(
		90deg,
		rgba(58, 181, 73, 1) 0%,
		rgba(142, 198, 63, 1) 100%
	);
	--color-whiteblue: #eef2ff;
	--color-red: #f63131;
	--shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.45);
	--shadow-light: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: var(--color-primary);
}
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(58, 181, 73, 0.7);
}
