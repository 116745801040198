@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,900&display=swap);
body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
button,
textarea {
	font-family: 'Montserrat', sans-serif;
}

:root {
	--color-primary: #3ab549;
	--color-secondary: #001051;
	--color-darkgray: #606060;
	--color-gray: #707070;
	--color-linear: linear-gradient(
		90deg,
		rgba(58, 181, 73, 1) 0%,
		rgba(142, 198, 63, 1) 100%
	);
	--color-whiteblue: #eef2ff;
	--color-red: #f63131;
	--shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.45);
	--shadow-light: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #3ab549;
	background-color: var(--color-primary);
}
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(58, 181, 73, 0.7);
}

.App-logo {
	height: 70%;
}
.container {
	display: flex;
	height: 90px;
	align-items: center;
	justify-content: space-evenly;
	grid-gap: 0 2vw;
	gap: 0 2vw;
	box-shadow: 1px 10px 35px -8px rgba(0, 16, 81, 0.4);
}

.web-list {
	list-style: none;
	font-weight: 500;
	font-size: 1rem;
	text-decoration: none;
	color: var(--color-secondary);
	cursor: pointer;
}
.active {
	color: var(--color-primary);
	list-style: none;
	font-weight: 700;
	font-size: 1rem;
	text-decoration: none;
	cursor: pointer;
}
.phone-list {
	text-decoration: none;
	list-style: none;
	font-weight: 500;
	color: #fff;
	cursor: pointer;
}
.height100 {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button {
	background: var(--color-linear);
	border: none;
	color: white;
	padding: 10px 15px;
	text-align: center;
	border-radius: 12px;
	font-size: 1.25rem;
	cursor: pointer;
}

.navMenu {
	position: fixed;
	inset: 0% 15% 0 0;
	background-color: var(--color-primary);
	box-shadow: 15px 9px 66px -8px rgba(0, 0, 0, 0.75);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-left: 15%;
}

.nav-logo {
	position: fixed;
	inset: 10% 0 0 81%;
}
.logo-outlined {
	width: 50%;
}
.space-evenly {
	justify-content: space-around;
}
.font-flexible {
	font-size: 1rem;
}
.mobile-menu {
	position: fixed;
	height: 6rem;
	width: 100vw;
	bottom: 0;
	background-color: var(--color-whiteblue);
	display: flex;
	justify-content: space-between;
}
.phone-nav-item {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	width: 24%;
	height: 100%;
	background-color: #fff;
	text-decoration: none;
	color: #000;
}
.nav-icon {
	height: 20%;
}
.nav-phone-text {
	text-align: center;
	font-size: 0.8rem;
}
.width-70 {
	width: 70vw;
}
.color-white {
	color: #fff;
}
.pointer {
	cursor: pointer;
}
.footer-text {
	font-weight: 300;
	font-size: 0.8rem;
	text-align: center;
}
.active-mobile-Nav {
	background-color: var(--color-primary);
	color: #fff;
	fill: white;
}
.logo-footer {
	margin-top: -2rem;
	height: 60%;
}
/* smartphone */
@media (max-width: 1000px) {
	.container {
		position: fixed;
		top: 0;
		justify-content: space-around;
		width: 100%;
		height: 100px;
		z-index: 100;
		background-color: #fff;
	}
	.button {
		font-size: 1rem;
		padding: 10px 10px;
	}
	.App-logo {
		height: 50%;
	}
	.width-70 {
		width: 95vw;
	}
	.color-white {
		font-size: 0.9rem;
	}
	.footer-text {
		font-size: 0.7rem;
	}
}
@media (max-width: 600px) {
	.container {
		position: relative;
		box-shadow: none;
	}
	.App-logo {
		height: 60%;
		margin: 0 auto;
	}
	.navMenu {
		margin-bottom: 6rem;
	}
	.nav-logo {
		inset: 5% 0 0 78%;
	}
	.color-white {
		font-size: 0.6rem;
	}
	.footer-text {
		font-size: 0.4rem;
	}
	.font-flexible {
		font-size: 0.5rem;
		text-align: center;
	}
	.logo-footer {
		height: 40%;
	}
}

.top-right {
	border-radius: 5px;
	margin-top: 15px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 5px 10px;
	margin-left: auto;
	margin-right: 5vw;
	background-color: #fff;
}
.headerFont {
	color: var(--color-secondary);
	font-weight: 500;
}
.flex {
	display: flex;
}
.center {
	justify-content: center;
	align-items: center;
}
.column {
	flex-direction: column;
}
.color-primary {
	color: var(--color-primary);
	font-weight: bold;
}
.img-header {
	height: 20%;
}
.bg-linear {
	background: var(--color-linear);
}
.no-margin {
	margin: 0;
}
.width-card {
	width: 18.5%;
}
.big-font {
	font-size: 2.3rem;
	text-align: center;
}
.center-content {
	align-items: center;
	justify-content: center;
}
.margin-top-2 {
	margin-top: 2rem;
}
.img-brand {
	width: 60%;
}
.medium-font {
	margin: 0 1rem;
	font-weight: 600;
}
.gap-6 {
	grid-gap: 6em;
	gap: 6em;
}
.card-container {
	height: 300px;
	width: 300px;
	border-radius: 20px;
	cursor: pointer;
}
.bg-secondary {
	background-color: var(--color-secondary);
}
.padding-top {
	padding-top: 100px;
}
.bg-whiteblue {
	background-color: var(--color-whiteblue);
}
.card-text {
	color: #fff;
	font-size: 2rem;
	font-weight: 600;
	text-align: center;
}

.video {
	height: 50vh;
	background-color: gray;
	width: 70vw;
	box-shadow: var(--shadow);
}
.question {
	background-color: #fff;
	margin-top: 2em;
	box-shadow: var(--shadow);
	align-items: center;
	border-radius: 8px;
	width: 70vw;
	cursor: pointer;
	color: var(--color-darkgray);
}
.reponse {
	margin-top: auto;
	width: 80%;
	color: #000;
	font-weight: 400;
}
.items-space-between {
	padding: 1.5rem 0;
	width: 80%;
	justify-content: space-between;
	align-items: center;
}
.arrow {
	height: 100%;
}

.border-Bottom {
	height: 7px;
	background-color: var(--color-darkgray);
	border-radius: 5px;
	width: 10vw;
	margin-bottom: 1.5rem;
}
.slider-trois {
	display: flex;
	justify-content: center;
}
.img-slider {
	object-fit: cover;
	height: 10em;
	max-width: 95%;
	border-bottom: 2px #000;
	padding-top: 0.3rem;
}
.Custom-slider {
	background-color: #fff;
	height: 12rem;
	width: 70vw;
	box-sizing: border-box;
	box-shadow: var(--shadow);
}
.verified {
	height: 1.8rem;
	margin-right: 0.5rem;
}
.avis {
	display: flex;
	flex-direction: column;
	height: 94%;
	align-items: center;
	justify-content: space-around;
	width: 80%;
	margin: 0 auto;
}
.commentaire {
	text-align: center;
}
.commentaire::before {
	content: open-quote;
}
.commentaire::after {
	content: close-quote;
}
.avatar {
	width: 10vh;
	height: 10vh;
	border-radius: 5vh;
	object-fit: cover;
	margin-right: 0.5rem;
}
.margin-bot {
	margin-bottom: 50vh;
}
.Custom-slider-phone {
	width: 98vw;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}
.avisPhone {
	width: 100vw;
}
.smart-img {
	border: 3px solid var(--color-primary);
}
.star {
	height: 1.5rem;
}
.blanco {
	margin: -10% 14vw 0 14vw;
	padding: 10% 0;
	background-color: white;
	width: 70vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	border-radius: 20px;
}
.actus-cards {
	width: 90vw;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
}
.actus-card {
	width: 42vw;
	border-radius: 15px;
	box-shadow: var(--shadow);
	cursor: pointer;
}
.card-content {
	width: 90%;
	justify-content: space-between;
	margin: 0 auto;
	height: 25%;
}
.infos-card {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 0;
	height: 100%;
}
.width-card-img {
	width: 100%;
	height: 70%;
	overflow: hidden;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	object-fit: cover;
}
.infos-title {
	color: var(--color-secondary);
	font-weight: 800;
	font-size: 1.1rem;
}
.center-self {
	margin: auto 0;
}
.big-image {
	height: 80%;
}
.small-content {
	height: 60%;
}
.hight-400 {
	height: 32em;
}
.hight-180 {
	height: 15em;
}
.space-around {
	justify-content: space-between;
}
.hashtag {
	color: var(--color-primary);
	font-weight: 800;
}
.hashtag::before {
	content: '#';
}
.right {
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1.2rem;
	width: 12em;
	margin-left: auto;
	margin-right: 4.5vw;
	color: var(--color-darkgray);
	border: 1px solid var(--color-darkgray);
	padding: 10px;
	font-weight: 500;
	font-size: 1rem;
	border-radius: 10px;
	cursor: pointer;
}
.spacing {
	height: 30px;
}
.color-white {
	color: #fff;
}
.fill-primary {
	cursor: pointer;
}
.text-center {
	text-align: center;
}
.height-30r {
	height: 23em;
	justify-content: space-around;
	box-shadow: var(--shadow);
	padding: 0 10vw;
}
.height-40 {
	height: 40vh;
	justify-content: space-around;
}
.input {
	display: flex;
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	width: 30vw;
	justify-content: space-between;
}
.inputMail {
	border-width: 0px;
	border: none;
	width: 80%;
}
.inputMail:focus {
	outline: none;
}
.mission-cards {
	width: 90vw;
	margin: 0 auto;
	grid-gap: 1.5em;
	gap: 1.5em;
	margin-bottom: 30px;
	box-sizing: border-box;
}
.Mission-card {
	box-sizing: border-box;
	padding: 0 1rem;
	width: 22%;
	height: 18rem;
	border-radius: 25px;
	background-color: white !important;
	justify-content: space-evenly;
	box-shadow: var(--shadow-light);
}
.num-mission {
	margin-top: 5%;
	margin-left: -110%;
	width: 1.5em;
	text-align: center;
	font-weight: 800;
	font-size: 2rem;
	border-radius: 10px;
}
.title-mission {
	font-weight: bold;
	color: var(--color-primary);
	font-size: 1.2rem;
	text-align: center;
}
.content-mission {
	margin-top: -0.2rem;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 12;
	-webkit-box-orient: vertical;
	white-space: normal;
	padding: 0 3%;
	font-size: 0.9rem;
	flex: 4 1;
	font-weight: bold;
	color: var(--color-secondary);
}
#center-content {
	justify-content: center;
	grid-gap: 20%;
	gap: 20%;
}
.color-secondary {
	color: var(--color-secondary);
}
.font-weight-500 {
	margin-top: 2%;
	font-weight: 500;
}
.Banner-buttons {
	width: 40em;
	display: flex;
	grid-gap: 2.5em;
	gap: 2.5em;
	height: 4em;
	margin-bottom: 2rem;
}
.banner-button {
	min-height: 60%;
	width: 45%;
	box-shadow: var(--shadow);
}
.bg-white {
	background-color: #fff !important;
}
.button-secondary {
	background: white;
	border: none;
	color: var(--color-secondary);
	padding: 10px 15px;
	text-align: center;
	border-radius: 12px;
	font-size: 1.25rem;
	cursor: pointer;
}
.margin-top-3rem {
	margin-top: 3rem;
}
.swiper-button-next {
	background-image: url(/static/media/swiperNext.7d37b0bc.svg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
}

.swiper-button-next::after {
	display: none;
}
.swiper-button-prev {
	background-image: url(/static/media/swiperPrev.c4248c2c.svg);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center;
}

.swiper-button-prev::after {
	display: none;
}
.margin-bottom-2 {
	margin-bottom: 2rem;
}
.margin-top-5 {
	margin-top: 5rem;
}
.new-top-right {
	position: absolute;
	top: 95px;
	right: 1rem;
}
.gap-small {
	grid-gap: 1rem;
	gap: 1rem;
}
.small-full {
	width: 100%;
	justify-content: space-around;
}
.new-top-right h3 {
	text-align: center;
	margin-bottom: 0.5rem;
}
.new-buttons-header {
	display: flex;
	color: white;
	align-items: center;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	padding: 0.5rem;
	border-radius: 0.5rem;
}
.new-buttons-header h5 {
	font-weight: 400;
}
.new-buttons-header h4 {
	font-weight: 500;
}
.new-buttons-header img {
	height: 1.5rem;
}
.btn-primary {
	background: var(--color-linear);
}
.btn-secondary {
	background-color: var(--color-secondary);
}
@media (max-width: 1000px) {
	.new-top-right {
		position: absolute;
		top: 105px;
		right: 1rem;
	}
	.margin-top-5 {
		margin-top: 6rem;
	}
	.big-font {
		font-size: 1.8rem;
	}
	.medium-font {
		font-size: 1.3rem;
		text-align: center;
	}
	.gap-6 {
		grid-gap: 4rem;
		gap: 4rem;
	}
	.video {
		height: 40vh;
	}
	.reponse {
		width: 90%;
	}
	.question {
		width: 90vw;
	}
	.items-space-between {
		width: 95%;
	}
	.card-text {
		font-size: 1.4rem;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.Custom-slider {
		width: 90vw;
	}
	.img-slider {
		max-width: 95%;
	}
	.hight-400 {
		height: 30vh;
		width: 90vw;
	}
	.hight-180 {
		width: 90vw;
	}
	.actus-cards {
		flex-direction: column;
		grid-gap: 1.5rem;
		gap: 1.5rem;
	}
	.big-image {
		height: 70%;
	}
	.small-content {
		height: 100%;
	}
	.right {
		margin: 0 auto;
	}
	.input {
		width: 60vw;
	}
	.height-40 {
		padding: 0 5vw;
	}
	.Mission-card {
		margin: 0 auto;
		width: 80vw;
		min-height: 10em;
		margin-bottom: 1rem;
	}
	.num-mission {
		margin-left: -105%;
		font-size: 1.8rem;
	}
	.button-secondary {
		font-size: 1rem;
		padding: 10px 10px;
	}
	.Banner-buttons {
		width: 100%;
		grid-gap: 1.8em;
		gap: 1.8em;
	}
	.Banner-button {
		width: 100%;
	}
	.height-30r {
		height: 18em;
	}
	.content-mission {
		margin-top: -1rem;
		font-size: 0.8rem;
		-webkit-line-clamp: 5;
	}
}

@media (max-width: 600px) {
	.top-right {
		margin-right: 0rem;
	}
	.Mission-card {
		height: 16em;
	}
	.big-font {
		font-size: 1.5rem;
	}
	.medium-font {
		font-size: 1rem;
		text-align: center;
	}
	.card-container {
		height: 40vw;
		width: 40vw;
		text-decoration: none;
	}
	.gap-6 {
		grid-gap: 1rem;
		gap: 1rem;
	}
	.video {
		height: 20vh;
		background-color: gray;
		width: 98vw;
	}
	.img-slider {
		padding-top: 0;
	}
	.infos-title {
		color: var(--color-secondary);
		font-weight: 800;
		font-size: 0.8rem;
	}
	.hashtag {
		font-size: 0.6rem;
		color: var(--color-primary);
		font-weight: 800;
	}
	.center-self {
		font-size: 0.8rem;
	}
	.input {
		width: 70vw;
	}
	.height-40 {
		padding-bottom: 20vw;
	}
	.title-mission {
		font-size: 1.2rem;
	}
	.content-mission {
		margin-top: 0rem;
		font-size: 0.8rem;
		-webkit-line-clamp: 6;
	}
	.Banner-buttons {
		height: 3.5em;
	}
	.new-top-right {
		right: 0;
		width: 100%;
	}
	.new-buttons-header h4 {
		font-size: 0.75rem;
	}
}

.input-label {
	height: 4rem;
	width: 99%;
	font-size: 1.2rem;
	border-width: 0px;
	border: none;
	box-shadow: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 5px;
	margin-left: -2px;
	box-sizing: border-box;
	padding: 0 0.8rem;
	color: var(--color-darkgray);
}
::-webkit-input-placeholder {
	/* Edge */
	color: #a8a8a8;
}

::placeholder {
	color: #a8a8a8;
}

.input-label:focus {
	outline: none !important;
	border: 1px solid var(--color-primary);
}
.label-text {
	font-size: 1.2rem;
	cursor: pointer;
}
.margin-0-auto {
	margin: 0 auto;
}
.form-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.form-section {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.rows {
	flex-direction: column;
	width: 100%;
}
.input-container {
	width: 50%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0.5rem;
}
.width-80 {
	width: 80vw;
	margin: 0 auto;
}

.header-devis {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 2rem;
}
.header-title {
	margin: 0;
	font-size: 4.5rem;
	background: -webkit-linear-gradient(
		rgba(142, 198, 63, 1),
		rgba(58, 181, 73, 1)
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.header-subtitle {
	margin: 0;
	font-size: 2.5rem;
	text-align: center;
}
.description-devis {
	width: 60%;
	font-size: 1.3rem;
	text-align: center;
}
.two-rows {
	display: flex;
	width: 103.8%;
	justify-content: space-between;
	margin-left: -7px;
}
.card-form-container {
	display: flex;
	justify-content: space-between;
}
.card-form {
	background-color: white;
	border-width: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18%;
	box-shadow: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 5px;
	font-size: 1.3rem;
	cursor: pointer;
	color: var(--color-darkgray);
	word-break: break-word;
}
.card-form:focus {
	outline: none !important;
	border: 1px solid var(--color-primary);
}
.all100p {
	min-width: 10%;
	border-width: 0px;
	background-color: white;
}
.medium {
	min-height: 3.5rem;
}
.height-9rem {
	height: 11rem;
	text-align: center;
	font-size: 1.1rem;
	box-sizing: border-box;
	padding: 0 0.5rem;
}
.width-17p {
	width: calc(100% / 5.5);
}
.big {
	min-height: 6rem;
}
.margin-top-1rem {
	margin-top: 1rem;
}
.margin-top-05rem {
	margin-top: 0.5rem;
}
.width-45p {
	width: 46%;
}
.width-32p {
	width: 32%;
}
.yes-no-icon {
	min-height: 50%;
	margin-right: 1rem;
}
.color-white {
	color: white;
}
.home-icon {
	height: 30%;
}
.modal {
	position: fixed;
	box-sizing: border-box;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	background-color: white;
	overflow-y: scroll;
	width: 70%;
	height: 60%;
	padding: 2rem 1.5rem;
	z-index: 1000;
}
.margin-bottom-1rem {
	margin-bottom: 0.8rem;
}
.justify-with-gap {
	justify-content: flex-start;
	grid-gap: 2%;
	gap: 2%;
}
.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	z-index: 1000;
}
.close-Model {
	height: 100%;
	cursor: pointer;
}
.headerNavigation {
	display: flex;
	justify-content: space-around;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	height: 3rem;
	min-width: 10%;
}
.submit {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	align-items: center;
	width: 100%;
	margin: 2rem 2rem;
}
.submit-button {
	align-self: center;
	width: 50%;
	min-height: 5rem;
	color: white;
	border: none;
	background-color: var(--color-primary);
	font-size: 1.8rem;
	border-radius: 0.5rem;
	font-weight: bold;
	cursor: pointer;
}
.p-submit {
	align-self: center;
	text-align: center;
}
.small {
	font-size: 1rem;
	text-align: center;
	box-sizing: border-box;
	padding: 0.5rem;
	height: 3.5rem;
}
.color-red {
	color: var(--color-red);
}
.red-border {
	border: 2px solid var(--color-red);
}
.wrap {
	flex-wrap: wrap;
}
.color-blue-underline {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}
.checkBoxContainer {
	display: flex;
	align-items: center;
	grid-gap: 0.5em;
	gap: 0.5em;
}
.checkBox {
	display: flex;
	width: 1.5em;
	height: 1.5em;
	border-radius: 0.2em;
	border: 2px solid var(--color-primary);
	cursor: pointer;
	background-color: white;
}
p {
	word-wrap: break-word;
}
.bg-primary {
	background-color: var(--color-primary);
}
.check-icon {
	width: 80%;
}
.font-condition {
	font-size: 1rem;
}
.partenaire-title {
	color: var(--color-primary);
	font-weight: bolder;
	text-transform: uppercase;
	margin: 2rem 0rem;
}
.upload-container {
	margin: 0rem 10%;
	display: flex;
	flex-direction: column;
}
.upload-item {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100%;
	min-height: 5rem;
	background-color: white;
	border-radius: 1rem;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	box-shadow: 0px 0px 24px 5px rgba(0, 0, 0, 0.1);
	grid-gap: 1rem;
	gap: 1rem;
	cursor: pointer;
}
.upload-item img {
	min-height: 1.5rem;
	width: 1.5rem;
}
.accepted-upload {
	background: var(--color-linear);
	color: white;
}
.upload-check {
	position: absolute;
	right: 12%;
	width: 1.5rem;
	min-height: 1.5rem;
}
.progressBar {
	position: fixed;
	height: 0.3rem;
	background: var(--color-linear);
	top: 0;
}
.disabled {
	opacity: 0.7;
	cursor: not-allowed;
}

/* tablette */
@media (max-width: 1000px) {
	.partenaire-title {
		margin: 1rem 0rem;
		font-size: 1.8rem;
	}
	.checkBoxContainer {
		max-width: 70%;
	}
	.checkBox {
		width: 2em;
	}
	.width-adresse {
		min-width: 102.5%;
	}
	.form-container {
		width: 100%;
	}
	.input-label {
		width: 100%;
	}
	.description-devis {
		width: 90%;
		font-size: 1rem;
	}
	.input-container-fully {
		width: 95%;
	}
	.two-rows {
		width: 80vw;
	}
	.height-9rem {
		height: 8rem;
		font-size: 1rem;
	}
	.headerNavigation {
		height: 2.5rem;
		min-width: 18%;
	}
	.modal {
		width: 80%;
	}
	.submit-button {
		width: 70%;
	}
}

/* smartphone */
@media (max-width: 600px) {
	.big {
		font-size: 1rem;
	}
	.medium {
		font-size: 1rem;
	}
	.input-label {
		font-size: 0.8rem;
	}
	.width-80 {
		width: 90vw;
	}
	.header-title {
		font-size: 2.5rem;
	}
	.header-subtitle {
		margin: 0;
		font-size: 1.5rem;
	}
	.label-text {
		font-size: 1rem;
	}
	.input-container-fully {
		width: 93.5%;
	}
	.two-rows {
		width: 90vw;
	}
	.height-9rem {
		height: 7rem;
		font-size: 0.6rem;
		padding: 0 2px;
	}
	.headerNavigation {
		height: 2rem;
		min-width: 28%;
	}
	.label-text {
		font-size: 0.8rem;
	}
	.modal {
		width: 90%;
	}
	.small {
		font-size: 0.8rem;
	}
	.submit-button {
		font-size: 1.3rem;
	}
	.width-17p {
		width: calc(100% / 3.5);
	}
	.checkBox {
		width: 3em;
	}
	.font-condition {
		font-size: 0.8rem;
	}
	.partenaire-title {
		font-size: 1.3rem;
		margin-top: 0;
	}
	.upload-item {
		font-size: 0.8rem;
		padding: 0rem 10%;
	}
}

.img-meeting {
	height: 70vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}
.solutions-text {
	margin: auto 15%;
	display: flex;
	flex-direction: column;
}
.solutions-text h1 {
	color: var(--color-secondary);
	align-self: center;
	text-align: center;
	font-family: 'Montserrat';
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 2rem;
}
.solutions-text p {
	margin-top: 2rem;
	text-align: center;
	color: var(--color-secondary);
	font-family: 'Montserrat';
	font-weight: 400;
}
.services-container {
	display: flex;
}
.service-cart {
	flex: 1 1;
	margin: 2rem 2%;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.service-cart hr {
	width: 20%;
	border: 0.1rem solid var(--color-primary);
	background-color: var(--color-primary);
}
.service-cart h1 {
	text-transform: uppercase;
	font-family: 'Montserrat';
	color: var(--color-secondary);
	font-size: 1.6rem;
	font-weight: bold;
	margin: 1rem 0rem;
}
.service-cart h2 {
	font-family: 'Montserrat';
	font-weight: 400;
	font-size: 1.5rem;
}
.service-cart p {
	font-weight: 400;
	font-size: 1rem;
	min-height: 2rem;
}
.service-cart img {
	width: 8%;
}
.service-cart button {
	margin-bottom: 1rem;
	border: 2px solid var(--color-primary);
	min-width: 50%;
	background-color: white;
	height: 4rem;
	border-radius: 10px;
	font-size: 1.2rem;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	transition: background 0.5s ease-in-out;
	/* a effacer */
	color: var(--color-primary);
}
.button-link {
	color: var(--color-primary);
	text-decoration: none;
}
.service-cart button:hover .button-link {
	color: white;
}
.service-cart button:hover {
	background-color: var(--color-primary);
	/* a effacer  */
	color: white;
}

.service-cart small {
	display: block;
}
.border-shadow {
	box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, 0.5);
	margin-bottom: 1rem;
}
.service-title {
	align-self: center;
	text-align: center;
}
.solutions-text hr {
	width: 20%;
	height: 0.4rem;
	border-radius: 0.5rem;
	border: none;
	background: var(--color-linear);
}
.modal-solutions p {
	margin-top: 2rem;
}
.title-solutions-modal {
	margin-top: 2rem;
	text-align: center;
}
.footer-solutions-container {
	display: flex;
	margin: 2rem 10% 0rem 10%;
	box-sizing: border-box;
}
.footer-info {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 5%;
	justify-content: space-between;
}
.footer-info h1 {
	text-transform: uppercase;
	color: var(--color-secondary);
}
.footer-info p {
	color: #606060;
	font-weight: 500;
	font-size: 1.2rem;
}
.img-femme {
	max-height: 60vh;
	align-self: flex-end;
	-webkit-filter: drop-shadow(0 0 0.5rem);
	        filter: drop-shadow(0 0 0.5rem);
	object-fit: contain;
}
.info-timing {
	display: flex;
	flex-direction: column;
}
.info-timing p {
	color: var(--color-secondary);
}
.phone-footer {
	display: flex;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
}
.phone-footer h1 {
	color: var(--color-primary);
}
.phone-footer img {
	height: 1.5rem;
}
.phone-footer {
	display: flex;
}

@media (max-width: 1000px) {
	.img-femme {
		max-height: 45vh;
		align-self: flex-end;
		-webkit-filter: drop-shadow(0 0 0.5rem);
		        filter: drop-shadow(0 0 0.5rem);
		object-fit: contain;
	}
	.img-meeting {
		height: 70vh;
	}
	.services-container {
		flex-direction: column;
	}
}

@media (max-width: 600px) {
	.solutions-text h1 {
		font-size: 1.5rem;
	}
	.img-meeting {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
	.footer-info p {
		font-size: 0.9rem;
	}
	.footer-info h1 {
		font-size: 1.2rem;
	}
	.phone-footer img {
		height: 1rem;
	}
}

.container-auth {
	margin: 2rem 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-gap: 1rem;
	gap: 1rem;
}
.container-auth h1 {
	text-align: center;
	color: var(--color-secondary);
	text-transform: uppercase;
}
.container-auth hr {
	width: 20%;
	height: 0.5rem;
	border-radius: 1rem;
	background-color: #707070;
	border: none;
}
.auth-button {
	margin-top: 1rem;
	width: 40%;
	padding: 1rem 0rem;
	border: none;
	color: white;
	background: var(--color-linear);
	font-size: 1.5rem;
	font-weight: 500;
	border-radius: 10px;
	cursor: pointer;
}
.container-auth button .container-auth a {
	color: #000;
}

.input-connexion {
	width: 50%;
	border: none;
	background-color: #f2f2f2;
	padding: 1rem;
	border-radius: 10px;
	min-height: 1.7rem;
}
.input-visible {
	display: flex;
	justify-content: center;
	align-items: center;
}
.input-visible input {
	width: 90%;
	border: none;
	background-color: inherit;
	height: 1.5rem;
}
.input-visible input:focus {
	outline: none;
}
.input-visible:focus-within {
	outline: auto;
}
.img-visible {
	cursor: pointer;
	flex: 1 1;
	height: 1.5rem;
	min-width: 10%;
}
.border-red {
	border: 2px solid var(--color-red);
}
.error-message {
	margin: 0;
	color: var(--color-red);
	text-align: center;
}
.disable-button {
	opacity: 0.7;
	cursor: not-allowed;
}
@media (max-width: 1000px) {
	.input-connexion {
		width: 80%;
	}
	.auth-button {
		width: 60%;
	}
}
@media (max-width: 600px) {
	.container-auth h1 {
		font-size: 1.8rem;
	}
	.auth-button {
		width: 65%;
		font-size: 1.2rem;
	}
}

.img-background-contact {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		url(/static/media/contactBanner.fd2f3807.png);
	height: 90vh;
	background-size: cover;
	background-position: 50% 0;
	object-fit: contain;
	background-repeat: no-repeat;
	overflow-x: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.img-background-contact h1 {
	text-align: center;
	text-transform: uppercase;
	color: white;
	font-size: 3rem;
}
.top-right-number {
	box-sizing: border-box;
	padding: 0.5rem 1rem;
	border-radius: 5px;
	top: 105px;
	right: 1rem;
	position: absolute;
	background-color: white;
}
.contact-container {
	display: flex;
	margin: 0 10%;
}
.femme-image {
	max-width: 30%;
	align-self: flex-end;
	-webkit-filter: drop-shadow(0 0 0.5rem);
	        filter: drop-shadow(0 0 0.5rem);
	object-fit: contain;
}
#error-border {
	border: 2px solid var(--color-red);
}
.contact-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	grid-gap: 4rem;
	gap: 4rem;
}
.contact-content h1 {
	font-size: 2.3rem;
}
.contact-buttons {
	display: flex;
	width: 80%;
	justify-content: space-between;
}
.contact-buttons img {
	height: 70%;
}
.contact-buttons button {
	padding: 1rem 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 5%;
	gap: 5%;
	width: 47%;
	border: none;
	cursor: pointer;
	border-radius: 1rem;
}
.contact-button-left {
	background-color: var(--color-secondary);
	color: white;
}
.contact-button-right {
	background: var(--color-linear);
	color: white;
}
.contact-button-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.contact-button-content h5 {
	font-weight: 400;
	font-size: 1.1rem;
}
.contact-button-content h4 {
	font-weight: 600;
	font-size: 1.1rem;
}
.contact-social-media {
	display: flex;
	grid-gap: 2rem;
	gap: 2rem;
}
.contact-social-media img {
	height: 2rem;
	cursor: pointer;
}
.line-contact {
	margin: 0;
	background-color: var(--color-primary);
	/* border-color: var(--color-primary); */
	border: none;
	height: 3px;
}
.contact-form-container {
	margin-top: 2rem;
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-gap: 3rem;
	gap: 3rem;
}

.contact-form-container input {
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid var(--color-primary);
	width: 60%;
}
.contact-form-container textarea {
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid var(--color-primary);
	width: 60%;
}
.contact-form-container button {
	padding: 1rem;
	min-width: 20%;
	border: none;
	border-radius: 0.5rem;
	background: var(--color-linear);
	color: white;
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
}

@media (max-width: 1000px) {
	.contact-content {
		min-height: 30rem;
	}
	.contact-buttons img {
		height: 60%;
	}
	.contact-buttons {
		width: 100%;
	}
}
@media (max-width: 1000px) {
	.contact-content {
		min-height: 30rem;
	}
	.contact-buttons img {
		height: 50%;
		width: 50%;
	}
	.contact-button-left img,
	.contact-button-right img {
		flex: 1 1;
		height: 1.5rem;
	}
	.contact-button-content {
		flex: 3 1;
	}
}
@media (max-width: 600px) {
	.contact-form-container h1 {
		font-size: 1.5rem;
		text-align: center;
	}
	.contact-content h1 {
		font-size: 1.8rem;
		text-align: center;
	}
	.contact-buttons {
		align-items: center;
		justify-content: center;
		flex-direction: column;
		grid-gap: 2rem;
		gap: 2rem;
	}
	.contact-buttons button {
		min-width: 90%;
	}
	.contact-button-content {
		width: 100%;
	}
	.contact-form-container input,
	.contact-form-container textarea {
		width: 70vw;
	}
}

.buttons-header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 3rem;
	margin-top: 6rem;
	padding: 0px 10%;
	box-sizing: border-box;
}
.button-header {
	box-sizing: border-box;
	width: 35%;
	min-height: 15rem;
	text-align: center;
	display: flex;
	border-radius: 0.5rem;
	box-shadow: var(--shadow-light);
	flex-direction: column;
	justify-content: space-around;
	color: var(--color-primary);
	padding: 1rem;
}
.button-header h1 {
	margin-top: 2rem;
	font-size: 4rem;
}
.button-header h2 {
	font-weight: 500;
	font-size: 2.2rem;
}
.button-header p {
	color: #000;
	font-size: 1.2rem;
	font-weight: 400;
}
.chantier-title {
	font-weight: 400;
	margin: 0% 10%;
}
.chantier-table {
	box-shadow: var(--shadow-light);
	border-radius: 0.5rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: 2rem 10%;
}
.chantier-row {
	display: flex;
	height: 5rem;
	align-items: center;
	text-align: center;
}
.chantier-row h4 {
	justify-self: center;
	font-weight: 400;
	color: #000;
	flex: 1 1;
}
.chantier-row h3 {
	justify-self: center;
	flex: 1 1;
	color: var(--color-secondary);
}
.big-button {
	margin: 3rem 10%;
	box-shadow: var(--shadow-light);
	text-align: center;
	padding: 2rem;
	border-radius: 0.5rem;
}
.big-button h1 {
	color: var(--color-primary);
	font-size: 5rem;
}
.big-button h2 {
	color: var(--color-primary);
	font-size: 2.2rem;
	font-weight: 500;
}
.demande-particulier-title {
	text-align: center;
	font-weight: 500;
	color: var(--color-secondary);
	margin-bottom: 2rem;
}
.buttons-footer {
	display: flex;
	margin: 0% 10%;
	grid-gap: 5%;
	gap: 5%;
	margin-bottom: 3rem;
}
.buttons-footer button {
	position: relative;
	flex: 1 1;
	height: 15rem;
	border: none;
	box-shadow: var(--shadow-light);
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-gap: 10%;
	gap: 10%;
	border-radius: 0.5rem;
}
.buttons-footer img {
	height: 3.5rem;
}
#btn-primary {
	background: var(--color-linear);
	color: white;
}

@media (max-width: 1000px) {
	.button-header {
		width: 45%;
	}
	.button-header h1 {
		font-size: 3rem;
	}
	.button-header h2 {
		font-size: 2rem;
	}
	.button-header p {
		font-size: 1rem;
	}
	.chantier-row h4 {
		font-size: 0.8rem;
	}
	.chantier-row h3 {
		font-size: 1rem;
	}
	.big-button {
		padding: 1rem;
	}
	.big-button h1 {
		color: var(--color-primary);
		font-size: 3rem;
	}
	.big-button h2 {
		color: var(--color-primary);
		font-size: 1.5rem;
		font-weight: 500;
	}
	.buttons-footer {
		flex-direction: column;
		grid-gap: 2rem;
		gap: 2rem;
	}
	.buttons-footer button {
		flex-direction: row;
		min-height: 6rem;
		justify-content: flex-start;
		padding-left: 10%;
	}
	.buttons-footer small {
		position: absolute;
		right: 4%;
		bottom: 10%;
	}
}
@media (max-width: 600px) {
	.big-button {
		margin: 3rem 5%;
	}
	.chantier-table {
		margin: 2rem 5%;
	}
	.buttons-header {
		padding: 0px 5%;
	}
	.button-header h1 {
		font-size: 2rem;
	}
	.button-header h2 {
		font-size: 1.2rem;
	}
	.button-header p {
		font-size: 0.8rem;
	}
	.chantier-row h4 {
		font-size: 0.6rem;
	}
	.chantier-row h3 {
		font-size: 0.7rem;
	}
	.chantier-title {
		margin: 0% 5%;
		font-size: 1rem;
	}
	.buttons-footer button {
		grid-gap: 5%;
		gap: 5%;
	}
	.buttons-footer h2 {
		font-size: 0.8rem;
	}
	.buttons-footer img {
		height: 1.5rem;
	}
}

.particulier-container {
	margin-top: 5rem;
}
.particulier-container h1 {
	text-transform: uppercase;
	font-weight: 500;
	text-align: center;
	color: var(--color-primary);
}
.schema-container {
	margin-top: 3rem;
	margin-bottom: 13rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0% 10%;
	box-sizing: border-box;
}

.step-left-container::after {
	content: '';
	position: absolute;
	width: 1rem;
	height: 1rem;
	background-color: white;
	right: 0%;
	top: 50%;
	box-shadow: 0.3rem -0.3rem 0.7rem 0px rgba(0, 0, 0, 0.1);
	-webkit-transform: translateY(-50%) rotate(45deg);
	        transform: translateY(-50%) rotate(45deg);
	margin-right: -0.5rem;
}
.step-left-container,
.step-right-container {
	flex: 1 1;
	position: relative;
	width: 30vw;
	max-height: 10rem;
	box-shadow: var(--shadow-light);
	border-radius: 1rem;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 10%;
	gap: 10%;
	padding: 1rem;
	box-sizing: border-box;
}
.step-left-container h1,
.step-right-container h1 {
	font-weight: 500;
	font-size: 3rem;
	color: #636363;
	flex: 1 1;
}
.step-left-container h3,
.step-right-container h3 {
	flex: 2 1;
}
#primary-color {
	color: var(--color-primary);
}
.step-right-container::after {
	content: '';
	position: absolute;
	width: 1rem;
	height: 1rem;
	background-color: white;
	left: 0%;
	top: 50%;
	box-shadow: -0.5rem 0.5rem 0.7rem 0px rgba(0, 0, 0, 0.1);
	-webkit-transform: translateY(-50%) rotate(45deg);
	        transform: translateY(-50%) rotate(45deg);
	margin-left: -0.5rem;
}

.schema-right {
	margin-top: 10rem;
	margin-bottom: -10rem;
}
.time-line {
	margin-right: 2rem;
	margin-left: 2rem;
	margin-top: 5rem;
	height: 50rem;
}
.schema-left,
.schema-right {
	display: flex;
	align-content: center;
	flex-direction: column;
}
.line {
	height: 8.3rem;
	background-color: inherit;
	margin: 0.7rem 0rem;
}
.line-content {
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}
.step-finished {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;
	gap: 0.5rem;
	text-transform: uppercase;
	color: var(--color-primary);
	font-weight: 500;
}
.step-finished img {
	height: 1rem;
	width: 1rem;
}
.download-content::after {
	content: '';
	position: absolute;
	width: 1rem;
	height: 1rem;
	background-color: #efefef;
	left: 0%;
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(45deg);
	        transform: translateY(-50%) rotate(45deg);
	margin-left: -0.5rem;
}
.download-content {
	background-color: #efefef;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
	flex: 1 1;
	width: 30vw;
	max-height: 12rem;
	border-radius: 1rem;
	display: flex;
	padding: 0rem 1rem;
	box-sizing: border-box;
}
.download-row {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	font-weight: 500;
}

.debut-travaux {
	top: -5%;
	left: 55%;
}
.visite-bureau {
	bottom: -5%;
	left: 55%;
}
.montant-devis {
	top: 35%;
	left: 52%;
}
.prime-cee {
	top: 20%;
	left: 15%;
}
.prime-renov {
	bottom: 20%;
	left: 15%;
}

.debut-travaux,
.montant-devis,
.visite-bureau,
.prime-cee,
.prime-renov {
	position: absolute;
	font-size: 0.9rem;
}

