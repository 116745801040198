.container-auth {
	margin: 2rem 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}
.container-auth h1 {
	text-align: center;
	color: var(--color-secondary);
	text-transform: uppercase;
}
.container-auth hr {
	width: 20%;
	height: 0.5rem;
	border-radius: 1rem;
	background-color: #707070;
	border: none;
}
.auth-button {
	margin-top: 1rem;
	width: 40%;
	padding: 1rem 0rem;
	border: none;
	color: white;
	background: var(--color-linear);
	font-size: 1.5rem;
	font-weight: 500;
	border-radius: 10px;
	cursor: pointer;
}
.container-auth button .container-auth a {
	color: #000;
}

.input-connexion {
	width: 50%;
	border: none;
	background-color: #f2f2f2;
	padding: 1rem;
	border-radius: 10px;
	min-height: 1.7rem;
}
.input-visible {
	display: flex;
	justify-content: center;
	align-items: center;
}
.input-visible input {
	width: 90%;
	border: none;
	background-color: inherit;
	height: 1.5rem;
}
.input-visible input:focus {
	outline: none;
}
.input-visible:focus-within {
	outline: auto;
}
.img-visible {
	cursor: pointer;
	flex: 1;
	height: 1.5rem;
	min-width: 10%;
}
.border-red {
	border: 2px solid var(--color-red);
}
.error-message {
	margin: 0;
	color: var(--color-red);
	text-align: center;
}
.disable-button {
	opacity: 0.7;
	cursor: not-allowed;
}
@media (max-width: 1000px) {
	.input-connexion {
		width: 80%;
	}
	.auth-button {
		width: 60%;
	}
}
@media (max-width: 600px) {
	.container-auth h1 {
		font-size: 1.8rem;
	}
	.auth-button {
		width: 65%;
		font-size: 1.2rem;
	}
}
