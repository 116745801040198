.img-background-contact {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		url('../assets/images/contactBanner.png');
	height: 90vh;
	background-size: cover;
	background-position: 50% 0;
	object-fit: contain;
	background-repeat: no-repeat;
	overflow-x: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.img-background-contact h1 {
	text-align: center;
	text-transform: uppercase;
	color: white;
	font-size: 3rem;
}
.top-right-number {
	box-sizing: border-box;
	padding: 0.5rem 1rem;
	border-radius: 5px;
	top: 105px;
	right: 1rem;
	position: absolute;
	background-color: white;
}
.contact-container {
	display: flex;
	margin: 0 10%;
}
.femme-image {
	max-width: 30%;
	align-self: flex-end;
	filter: drop-shadow(0 0 0.5rem);
	object-fit: contain;
}
#error-border {
	border: 2px solid var(--color-red);
}
.contact-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 4rem;
}
.contact-content h1 {
	font-size: 2.3rem;
}
.contact-buttons {
	display: flex;
	width: 80%;
	justify-content: space-between;
}
.contact-buttons img {
	height: 70%;
}
.contact-buttons button {
	padding: 1rem 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5%;
	width: 47%;
	border: none;
	cursor: pointer;
	border-radius: 1rem;
}
.contact-button-left {
	background-color: var(--color-secondary);
	color: white;
}
.contact-button-right {
	background: var(--color-linear);
	color: white;
}
.contact-button-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.contact-button-content h5 {
	font-weight: 400;
	font-size: 1.1rem;
}
.contact-button-content h4 {
	font-weight: 600;
	font-size: 1.1rem;
}
.contact-social-media {
	display: flex;
	gap: 2rem;
}
.contact-social-media img {
	height: 2rem;
	cursor: pointer;
}
.line-contact {
	margin: 0;
	background-color: var(--color-primary);
	/* border-color: var(--color-primary); */
	border: none;
	height: 3px;
}
.contact-form-container {
	margin-top: 2rem;
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3rem;
}

.contact-form-container input {
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid var(--color-primary);
	width: 60%;
}
.contact-form-container textarea {
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid var(--color-primary);
	width: 60%;
}
.contact-form-container button {
	padding: 1rem;
	min-width: 20%;
	border: none;
	border-radius: 0.5rem;
	background: var(--color-linear);
	color: white;
	font-size: 1.5rem;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
}

@media (max-width: 1000px) {
	.contact-content {
		min-height: 30rem;
	}
	.contact-buttons img {
		height: 60%;
	}
	.contact-buttons {
		width: 100%;
	}
}
@media (max-width: 1000px) {
	.contact-content {
		min-height: 30rem;
	}
	.contact-buttons img {
		height: 50%;
		width: 50%;
	}
	.contact-button-left img,
	.contact-button-right img {
		flex: 1;
		height: 1.5rem;
	}
	.contact-button-content {
		flex: 3;
	}
}
@media (max-width: 600px) {
	.contact-form-container h1 {
		font-size: 1.5rem;
		text-align: center;
	}
	.contact-content h1 {
		font-size: 1.8rem;
		text-align: center;
	}
	.contact-buttons {
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 2rem;
	}
	.contact-buttons button {
		min-width: 90%;
	}
	.contact-button-content {
		width: 100%;
	}
	.contact-form-container input,
	.contact-form-container textarea {
		width: 70vw;
	}
}
