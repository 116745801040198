.buttons-header {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 3rem;
	margin-top: 6rem;
	padding: 0px 10%;
	box-sizing: border-box;
}
.button-header {
	box-sizing: border-box;
	width: 35%;
	min-height: 15rem;
	text-align: center;
	display: flex;
	border-radius: 0.5rem;
	box-shadow: var(--shadow-light);
	flex-direction: column;
	justify-content: space-around;
	color: var(--color-primary);
	padding: 1rem;
}
.button-header h1 {
	margin-top: 2rem;
	font-size: 4rem;
}
.button-header h2 {
	font-weight: 500;
	font-size: 2.2rem;
}
.button-header p {
	color: #000;
	font-size: 1.2rem;
	font-weight: 400;
}
.chantier-title {
	font-weight: 400;
	margin: 0% 10%;
}
.chantier-table {
	box-shadow: var(--shadow-light);
	border-radius: 0.5rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: 2rem 10%;
}
.chantier-row {
	display: flex;
	height: 5rem;
	align-items: center;
	text-align: center;
}
.chantier-row h4 {
	justify-self: center;
	font-weight: 400;
	color: #000;
	flex: 1;
}
.chantier-row h3 {
	justify-self: center;
	flex: 1;
	color: var(--color-secondary);
}
.big-button {
	margin: 3rem 10%;
	box-shadow: var(--shadow-light);
	text-align: center;
	padding: 2rem;
	border-radius: 0.5rem;
}
.big-button h1 {
	color: var(--color-primary);
	font-size: 5rem;
}
.big-button h2 {
	color: var(--color-primary);
	font-size: 2.2rem;
	font-weight: 500;
}
.demande-particulier-title {
	text-align: center;
	font-weight: 500;
	color: var(--color-secondary);
	margin-bottom: 2rem;
}
.buttons-footer {
	display: flex;
	margin: 0% 10%;
	gap: 5%;
	margin-bottom: 3rem;
}
.buttons-footer button {
	position: relative;
	flex: 1;
	height: 15rem;
	border: none;
	box-shadow: var(--shadow-light);
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10%;
	border-radius: 0.5rem;
}
.buttons-footer img {
	height: 3.5rem;
}
#btn-primary {
	background: var(--color-linear);
	color: white;
}

@media (max-width: 1000px) {
	.button-header {
		width: 45%;
	}
	.button-header h1 {
		font-size: 3rem;
	}
	.button-header h2 {
		font-size: 2rem;
	}
	.button-header p {
		font-size: 1rem;
	}
	.chantier-row h4 {
		font-size: 0.8rem;
	}
	.chantier-row h3 {
		font-size: 1rem;
	}
	.big-button {
		padding: 1rem;
	}
	.big-button h1 {
		color: var(--color-primary);
		font-size: 3rem;
	}
	.big-button h2 {
		color: var(--color-primary);
		font-size: 1.5rem;
		font-weight: 500;
	}
	.buttons-footer {
		flex-direction: column;
		gap: 2rem;
	}
	.buttons-footer button {
		flex-direction: row;
		min-height: 6rem;
		justify-content: flex-start;
		padding-left: 10%;
	}
	.buttons-footer small {
		position: absolute;
		right: 4%;
		bottom: 10%;
	}
}
@media (max-width: 600px) {
	.big-button {
		margin: 3rem 5%;
	}
	.chantier-table {
		margin: 2rem 5%;
	}
	.buttons-header {
		padding: 0px 5%;
	}
	.button-header h1 {
		font-size: 2rem;
	}
	.button-header h2 {
		font-size: 1.2rem;
	}
	.button-header p {
		font-size: 0.8rem;
	}
	.chantier-row h4 {
		font-size: 0.6rem;
	}
	.chantier-row h3 {
		font-size: 0.7rem;
	}
	.chantier-title {
		margin: 0% 5%;
		font-size: 1rem;
	}
	.buttons-footer button {
		gap: 5%;
	}
	.buttons-footer h2 {
		font-size: 0.8rem;
	}
	.buttons-footer img {
		height: 1.5rem;
	}
}
