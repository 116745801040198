.App-logo {
	height: 70%;
}
.container {
	display: flex;
	height: 90px;
	align-items: center;
	justify-content: space-evenly;
	gap: 0 2vw;
	box-shadow: 1px 10px 35px -8px rgba(0, 16, 81, 0.4);
}

.web-list {
	list-style: none;
	font-weight: 500;
	font-size: 1rem;
	text-decoration: none;
	color: var(--color-secondary);
	cursor: pointer;
}
.active {
	color: var(--color-primary);
	list-style: none;
	font-weight: 700;
	font-size: 1rem;
	text-decoration: none;
	cursor: pointer;
}
.phone-list {
	text-decoration: none;
	list-style: none;
	font-weight: 500;
	color: #fff;
	cursor: pointer;
}
.height100 {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button {
	background: var(--color-linear);
	border: none;
	color: white;
	padding: 10px 15px;
	text-align: center;
	border-radius: 12px;
	font-size: 1.25rem;
	cursor: pointer;
}

.navMenu {
	position: fixed;
	inset: 0% 15% 0 0;
	background-color: var(--color-primary);
	box-shadow: 15px 9px 66px -8px rgba(0, 0, 0, 0.75);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-left: 15%;
}

.nav-logo {
	position: fixed;
	inset: 10% 0 0 81%;
}
.logo-outlined {
	width: 50%;
}
.space-evenly {
	justify-content: space-around;
}
.font-flexible {
	font-size: 1rem;
}
.mobile-menu {
	position: fixed;
	height: 6rem;
	width: 100vw;
	bottom: 0;
	background-color: var(--color-whiteblue);
	display: flex;
	justify-content: space-between;
}
.phone-nav-item {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	width: 24%;
	height: 100%;
	background-color: #fff;
	text-decoration: none;
	color: #000;
}
.nav-icon {
	height: 20%;
}
.nav-phone-text {
	text-align: center;
	font-size: 0.8rem;
}
.width-70 {
	width: 70vw;
}
.color-white {
	color: #fff;
}
.pointer {
	cursor: pointer;
}
.footer-text {
	font-weight: 300;
	font-size: 0.8rem;
	text-align: center;
}
.active-mobile-Nav {
	background-color: var(--color-primary);
	color: #fff;
	fill: white;
}
.logo-footer {
	margin-top: -2rem;
	height: 60%;
}
/* smartphone */
@media (max-width: 1000px) {
	.container {
		position: fixed;
		top: 0;
		justify-content: space-around;
		width: 100%;
		height: 100px;
		z-index: 100;
		background-color: #fff;
	}
	.button {
		font-size: 1rem;
		padding: 10px 10px;
	}
	.App-logo {
		height: 50%;
	}
	.width-70 {
		width: 95vw;
	}
	.color-white {
		font-size: 0.9rem;
	}
	.footer-text {
		font-size: 0.7rem;
	}
}
@media (max-width: 600px) {
	.container {
		position: relative;
		box-shadow: none;
	}
	.App-logo {
		height: 60%;
		margin: 0 auto;
	}
	.navMenu {
		margin-bottom: 6rem;
	}
	.nav-logo {
		inset: 5% 0 0 78%;
	}
	.color-white {
		font-size: 0.6rem;
	}
	.footer-text {
		font-size: 0.4rem;
	}
	.font-flexible {
		font-size: 0.5rem;
		text-align: center;
	}
	.logo-footer {
		height: 40%;
	}
}
