.particulier-container {
	margin-top: 5rem;
}
.particulier-container h1 {
	text-transform: uppercase;
	font-weight: 500;
	text-align: center;
	color: var(--color-primary);
}
.schema-container {
	margin-top: 3rem;
	margin-bottom: 13rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0% 10%;
	box-sizing: border-box;
}

.step-left-container::after {
	content: '';
	position: absolute;
	width: 1rem;
	height: 1rem;
	background-color: white;
	right: 0%;
	top: 50%;
	box-shadow: 0.3rem -0.3rem 0.7rem 0px rgba(0, 0, 0, 0.1);
	transform: translateY(-50%) rotate(45deg);
	margin-right: -0.5rem;
}
.step-left-container,
.step-right-container {
	flex: 1;
	position: relative;
	width: 30vw;
	max-height: 10rem;
	box-shadow: var(--shadow-light);
	border-radius: 1rem;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10%;
	padding: 1rem;
	box-sizing: border-box;
}
.step-left-container h1,
.step-right-container h1 {
	font-weight: 500;
	font-size: 3rem;
	color: #636363;
	flex: 1;
}
.step-left-container h3,
.step-right-container h3 {
	flex: 2;
}
#primary-color {
	color: var(--color-primary);
}
.step-right-container::after {
	content: '';
	position: absolute;
	width: 1rem;
	height: 1rem;
	background-color: white;
	left: 0%;
	top: 50%;
	box-shadow: -0.5rem 0.5rem 0.7rem 0px rgba(0, 0, 0, 0.1);
	transform: translateY(-50%) rotate(45deg);
	margin-left: -0.5rem;
}

.schema-right {
	margin-top: 10rem;
	margin-bottom: -10rem;
}
.time-line {
	margin-right: 2rem;
	margin-left: 2rem;
	margin-top: 5rem;
	height: 50rem;
}
.schema-left,
.schema-right {
	display: flex;
	align-content: center;
	flex-direction: column;
}
.line {
	height: 8.3rem;
	background-color: inherit;
	margin: 0.7rem 0rem;
}
.line-content {
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}
.step-finished {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	text-transform: uppercase;
	color: var(--color-primary);
	font-weight: 500;
}
.step-finished img {
	height: 1rem;
	width: 1rem;
}
.download-content::after {
	content: '';
	position: absolute;
	width: 1rem;
	height: 1rem;
	background-color: #efefef;
	left: 0%;
	top: 50%;
	transform: translateY(-50%) rotate(45deg);
	margin-left: -0.5rem;
}
.download-content {
	background-color: #efefef;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
	flex: 1;
	width: 30vw;
	max-height: 12rem;
	border-radius: 1rem;
	display: flex;
	padding: 0rem 1rem;
	box-sizing: border-box;
}
.download-row {
	display: flex;
	gap: 1rem;
	font-weight: 500;
}

.debut-travaux {
	top: -5%;
	left: 55%;
}
.visite-bureau {
	bottom: -5%;
	left: 55%;
}
.montant-devis {
	top: 35%;
	left: 52%;
}
.prime-cee {
	top: 20%;
	left: 15%;
}
.prime-renov {
	bottom: 20%;
	left: 15%;
}

.debut-travaux,
.montant-devis,
.visite-bureau,
.prime-cee,
.prime-renov {
	position: absolute;
	font-size: 0.9rem;
}
